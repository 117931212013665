import React, { useEffect, useState } from "react";
import { Accordion, Alert, Badge, Button, Card, Container, ListGroup, Spinner, Stack, Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { getMenus } from "../../services/api";
import moment from "moment";
import { Map as MapIcon } from "react-bootstrap-icons";

const dateFormat = "DD.MM.YYYY";

interface MenuType {
  mensa: { id: string; name: string };
  meals: {
    name: string | undefined;
    tag: string | undefined;
    components: string | undefined;
    price: string | undefined;
  }[];
}

export const Menu = ({ detailed }: { detailed: boolean }) => {
  const dates = [...new Array(7).keys()].map((d) => moment().add(d, "days")).filter((date) => date.weekday());
  const tags = ["Vegetarisches Gericht", "Veganes Gericht", "Fleischgericht", "Fischgericht", "Pizza", "Pastateller", "WOK", "Beilage", "Weiteres"];

  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [menus, setMenus] = useState<Map<string, MenuType[]>>(new Map());
  const [shownMenus, setShownMenus] = useState<Map<string, MenuType[]>>(new Map());
  const [hiddenMenus, setHiddenMenus] = useState<Map<string, MenuType[]>>(new Map());
  const [selectedMensa, setSelectedMensa] = useState<string>();
  const [selectedTags, setSelectedTags] = useState<Map<string, boolean>>(new Map());

  useEffect(() => {
    getMenus().then(setMenus);
  }, []);

  useEffect(() => {
    if (searchParams.get("locations") || !menus.size) return;
    setSearchParams({
      locations: menus
        .get(dates[0].format(dateFormat))!
        .map((menu) => menu.mensa.id)
        .join(","),
    });
    // eslint-disable-next-line
  }, [menus]);

  useEffect(() => {
    if (searchParams.get("locations") === null || !menus.size) return;
    setLoading(false);

    setShownMenus(
      new Map(
        getDates()
          .map((date) => date.format(dateFormat))
          .map((date) => [
            date,
            getLocations()
              .map((location) => menus.get(date)!.find(({ mensa }) => mensa.id === location)!)
              .map((menu) => {
                const menuCopy: MenuType = JSON.parse(JSON.stringify(menu));
                menuCopy.meals = menuCopy.meals.filter(({ tag }) => matchesTag(tag!));
                return menuCopy;
              }),
          ])
      )
    );
    setHiddenMenus(
      new Map(
        getDates()
          .map((date) => date.format(dateFormat))
          .map((date) => [date, menus.get(date)!.filter(({ mensa }) => !getLocations().includes(mensa.id))])
      )
    );
    // eslint-disable-next-line
  }, [menus, searchParams, selectedTags]);

  useEffect(() => {
    const extended = searchParams.get("extended");
    if (extended) setSelectedMensa(extended);
  }, [searchParams]);

  useEffect(() => {
    tags.forEach((tag) =>
      setSelectedTags((selTags) => {
        selTags.set(tag, searchParams.get(tag) !== "0");
        return new Map(selTags);
      })
    );
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    setSearchParams((params) => {
      if (!selectedMensa) params.delete("extended");
      else params.set("extended", selectedMensa);
      return params;
    });
    // eslint-disable-next-line
  }, [selectedMensa]);

  useEffect(() => {
    if (!selectedTags.size) return;

    setSearchParams((params) => {
      [...selectedTags.keys()].forEach((tag) => params.set(tag, selectedTags.get(tag)! ? "1" : "0"));
      return params;
    });
    // eslint-disable-next-line
  }, [selectedTags]);

  function getId(
    {
      name,
      tag,
      components,
      price,
    }: {
      name: string | undefined;
      tag: string | undefined;
      components: string | undefined;
      price: string | undefined;
    },
    id: number
  ) {
    return `${name}:${tag}:${components}:${price}@${id}`;
  }

  function getDates(): moment.Moment[] {
    return [...menus.keys()].map((datestr) => moment(datestr, dateFormat));
  }

  function getLocations(): string[] {
    const locations = searchParams.get("locations");
    if (!locations) return [];
    return locations.split(",");
  }

  function getBg(tag: string | undefined) {
    if (tag?.match(/(Vegetarisches)|(Veganes) Gericht/)) return "success";
    if (tag?.match(/Fleischgericht/)) return "danger";
    if (tag?.match(/(Pizza|Pastateller)/)) return "warning";
    if (tag?.match(/Fischgericht/)) return "primary";
    if (tag?.match(/WOK/)) return "info";
    if (tag?.match(/Weiteres/)) return "light";
    return "secondary";
  }

  function hideLocation(location: string) {
    setSearchParams((params) => {
      let locations = params.get("locations")!.split(",");
      locations = locations.filter((l) => l !== location);
      params.set("locations", locations.join(","));
      return params;
    });
  }

  function showLocation(location: string) {
    setSearchParams((params) => {
      let locations = params.get("locations")!.split(",");
      locations = [...locations.filter((l) => l !== location && l), location];
      params.set("locations", locations.join(","));
      return params;
    });
  }

  function collapseHandler(menu: MenuType) {
    setSelectedMensa((selected) => (selected !== menu.mensa.id ? menu.mensa.id : undefined));
  }

  function matchesTag(tag: string) {
    const target_tag = [...selectedTags.entries()].find(([t, a]) => tag.toLowerCase().includes(t.toLowerCase()));
    if (!target_tag) return !!selectedTags.get("Weiteres");
    return target_tag.at(1);
  }

  function toogleTag(tag: string) {
    setSelectedTags((selTags) => {
      selTags.set(tag, !selTags.get(tag)!);
      return new Map(selTags);
    });
  }

  return (
    <>
      {loading ? (
        <Container className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
          <Spinner animation="border" role="<output>">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Container>
      ) : (
        <>
          <Card className="mb-3">
            <Card.Header>
              <h4 className="mb-0">Filter</h4>
            </Card.Header>
            <Card.Body as={Stack} direction="horizontal" gap={1} className="flex-wrap">
              {[...selectedTags.keys()].map((tag) => (
                <h4 className="mb-0" key={tag}>
                  <Badge
                    key={tag}
                    pill
                    bg={selectedTags.get(tag) ? getBg(tag) : "dark"}
                    text={selectedTags.get(tag) && getBg(tag) === "light" ? "dark" : "light"}
                    as={Button}
                    type="checkbox"
                    style={{ border: "1px solid white" }}
                    onClick={() => toogleTag(tag)}
                  >
                    {tag}
                  </Badge>
                </h4>
              ))}
            </Card.Body>
          </Card>

          <Tabs
            defaultActiveKey={getDates()
              .find((date) => moment.duration(moment().diff(date)).asHours() < 18)!
              .format(dateFormat)}
          >
            {getDates().map((date) => (
              <Tab title={["Mo", "Di", "Mi", "Do", "Fr", "Sa"][date.weekday() - 1]} eventKey={date.format(dateFormat)} key={date.format(dateFormat)}>
                <Accordion className="pt-3" activeKey={selectedMensa}>
                  {shownMenus.get(date.format(dateFormat))!.map((menu, i) => (
                    <Accordion.Item eventKey={menu.mensa.id} key={menu.mensa.id}>
                      <Accordion.Header
                        onClick={() => collapseHandler(menu)}
                        onContextMenu={(event) => {
                          event.preventDefault();
                          hideLocation(menu.mensa.id);
                        }}
                      >
                        {menu.mensa.name}
                      </Accordion.Header>
                      <Accordion.Body>
                        {detailed && (
                          <Button
                            className="mb-3"
                            href={"https://maps.google.com/?q=" + menu.mensa.name + " Leipzig"}
                            target="_blank"
                            variant="info"
                            color="light"
                          >
                            <MapIcon /> Zu Google Maps
                          </Button>
                        )}
                        <Stack gap={3}>
                          {menu.meals.length ? (
                            menu.meals.map((meal, m) => (
                              <Card key={getId(meal, m)}>
                                <Card.Header>
                                  {meal.name}&nbsp;
                                  <Badge bg={getBg(meal.tag)}>{meal.tag}</Badge>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Title>{meal.price}</Card.Title>
                                  <Card.Text>{meal.components}</Card.Text>
                                </Card.Body>
                              </Card>
                            ))
                          ) : (
                            <>
                              {menus.get(date.format(dateFormat))!.find((m) => m.mensa.id === menu.mensa.id)!.meals.length ? (
                                <Alert variant="info" className="mb-0">
                                  Keine dem Filter entsprechenden Mahlzeiten verfügbar!
                                </Alert>
                              ) : (
                                <Alert variant="warning" className="mb-0">
                                  Keine Mahlzeiten verfügbar!
                                </Alert>
                              )}
                            </>
                          )}
                        </Stack>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                <hr />
                <ListGroup>
                  {hiddenMenus.get(date.format(dateFormat))!.map(({ mensa }) => (
                    <ListGroup.Item eventKey={mensa.id} key={mensa.id} variant="secondary" action active={false} onClick={() => showLocation(mensa.id)}>
                      {mensa.name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Tab>
            ))}
          </Tabs>
        </>
      )}
    </>
  );
};

import { faCircle as faCircleFilled } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import { Stack } from "react-bootstrap";
import { IconProps } from "react-bootstrap-icons";
import "./SkillPoints.sass";

export const SkillPoints = ({ name, Icon, points }: { name: string; Icon: FunctionComponent<IconProps>; points: number }) => {
  return (
    <tr>
      <td className="skill-icon">
        <Icon size="1.5em" />
      </td>
      <td className="skill-name">
        <h5 className="mb-0">{name}</h5>
      </td>
      <td className="skill-points">
        <Stack direction="horizontal" gap={1}>
          {[...new Array(points).keys()].map((point) => (
            <FontAwesomeIcon icon={faCircleFilled} key={point}></FontAwesomeIcon>
          ))}
          {[...new Array(10 - points).keys()].map((point) => (
            <FontAwesomeIcon icon={faCircle} key={point}></FontAwesomeIcon>
          ))}
        </Stack>
      </td>
    </tr>
  );
};

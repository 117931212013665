import React, { useEffect } from "react";
import { Button, FormSelect, Stack } from "react-bootstrap";
import { ModuleType } from "../../types/attendance";
import { toString } from "../../helper/timetable";

export const TimetableEntry = ({
  modules,
  entry,
  onSelect,
  onDelete,
}: {
  modules: ModuleType[];
  entry: Entry;
  onSelect: (entry: Entry) => void;
  onDelete: (entry: Entry) => void;
}) => {
  const [type, setType] = React.useState<string>();
  const [name, setName] = React.useState<string>();
  const [group, setGroup] = React.useState<string>();

  useEffect(() => {
    if (!name && !group) return;
    onSelect({ id: entry.id, type, name, group });
    // eslint-disable-next-line
  }, [type, name, group]);

  function getModuleByString(module: string) {
    return modules.find((m) => toString(m) === module);
  }

  function setModule(module: string) {
    const mod = getModuleByString(module);
    setType(mod?.type);
    setName(mod?.name);
  }

  return (
    <div>
      <Stack direction="horizontal" gap={3}>
        <FormSelect onChange={(e) => setModule(e.target.value)}>
          <option value="">--Modul auswählen--</option>
          {[...new Set(modules.map(toString))].map((module) => (
            <option key={module} value={module}>
              {module}
            </option>
          ))}
        </FormSelect>
        <FormSelect onChange={(e) => setGroup(e.target.value)}>
          <option value="">--Gruppe auswählen--</option>
          {[...new Set(modules.filter(({ name: mName, type: mType, group }) => mType === type && mName === name && group).map(({ group }) => group))].map(
            (group) => (
              <option key={group} value={group}>
                {group}
              </option>
            )
          )}
        </FormSelect>
        <Button onClick={() => onDelete(entry)} variant="danger">
          Löschen
        </Button>
      </Stack>
      <br></br>
    </div>
  );
};

import { Button, Container, Form, Table } from "react-bootstrap";
import { ImageInput } from "../../components/ImageInput/ImageInput";
import "./DigitalRuler.sass";
import React, { useEffect } from "react";
import { Trash } from "react-bootstrap-icons";
import { getDistance, getLength, Line, lineToString, Point } from "../../helper/line";
import { LineEntry } from "./LineEntry";
import { round } from "../../helper/math";

export const DigitalRuler = () => {
  const [image, setImage] = React.useState<string>();
  const [temp, setTemp] = React.useState<Point>();
  const [lines, setLines] = React.useState<Line[]>([]);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [pixels, setPixels] = React.useState<number>(0);

  useEffect(() => {
    const [canvas, ctx] = getCanvas();
    if (!canvas || !ctx || !image) return;

    const imageSrc = new Image();
    imageSrc.onload = () => {
      canvas.width = imageSrc.width;
      canvas.height = imageSrc.height;
      setPixels(imageSrc.width * imageSrc.height);
      ctx.drawImage(imageSrc, 0, 0);
      lines.forEach(drawLine);
      if (temp) drawPoint(temp);
    };
    imageSrc.src = image;
  }, [image, lines, temp]);

  function resetImage(image: string) {
    setImage(image);
    setLines([]);
    setTemp(undefined);
  }

  function handleClick(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) {
    const point = getPoint(event);
    if (!point) return;

    if (!temp) setTemp(point);
    else {
      const newLine: Line = { a: temp, b: point, length: 0, highlighted: false };
      setLines((lines) => [...lines, newLine]);
      const relativeLine = lines.find((l) => l.length !== 0);
      if (relativeLine) updateLengths(relativeLine);
      setTemp(undefined);
    }
  }

  function handleRightClick(e: React.MouseEvent<HTMLCanvasElement, MouseEvent>): void {
    e.preventDefault();
    const isNear = !!lines.find((l) => inRange(e, l));
    if (!isNear) undo();
    else setLines((lines) => {
      const nearest = lines.find((l) => inRange(e, l));
      if (nearest) return lines.filter((l) => l !== nearest);
      return lines;
    });
  }

  function undo(): void {
    if (temp) setTemp(undefined);
    else setLines((lines) => lines.slice(0, -1));
  }

  function drawLine(line: Line) {
    const [canvas, ctx] = getCanvas();
    if (!canvas || !ctx) return;

    const {
      a: [xa, ya],
      b: [xb, yb],
    } = line;
    ctx.beginPath();
    ctx.moveTo(xa, ya);
    ctx.lineTo(xb, yb);
    ctx.strokeStyle = line.highlighted ? "yellow" : "blue";
    ctx.lineWidth = 1e-6 * pixels;
    ctx.stroke();
    ctx.closePath();
  }

  function drawPoint(point: Point) {
    const [canvas, ctx] = getCanvas();
    if (!canvas || !ctx) return;

    const [x, y] = point;
    ctx.beginPath();
    ctx.fillStyle = "red";
    ctx.arc(x, y, 1e-6 * pixels, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();
  }

  function getPoint(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>): [number, number] | undefined {
    const [canvas, ctx] = getCanvas();
    if (!canvas || !ctx) return;

    const [widthFactor, heightFactor] = [canvas.width / canvas.offsetWidth, canvas.height / canvas.offsetHeight];
    const [x, y] = [widthFactor * (event.clientX - canvas.offsetLeft + window.scrollX), heightFactor * (event.clientY - canvas.offsetTop + window.scrollY)];
    return [x, y];
  }

  function getCanvas() {
    const canvas = canvasRef.current;
    return [canvas, canvas?.getContext("2d")] as [HTMLCanvasElement?, CanvasRenderingContext2D?];
  }

  function highlightLine(line: Line) {
    setLines((lines) => lines.map((l) => ({ ...l, highlighted: l === line })));
  }

  function blurLines() {
    setLines((lines) => lines.map((l) => ({ ...l, highlighted: false })));
  }

  function updateLengths(line: Line, length: number = line.length) {
    setLines((lines) => {
      const factor = length / getLength(line);

      return lines.map((l) => (l === line ? line : { ...l, length: round(getLength(l) * factor, 2) }));
    });
  }

  function deleteLine(line: Line) {
    setLines((lines) => lines.filter((l) => l !== line));
  }

  function inRange(e: React.MouseEvent<HTMLCanvasElement>, line: Line): boolean {
    const mouse = getPoint(e);
    if (!mouse) return false;
    return getDistance(line, mouse) < 3e-6 * pixels;
  }

  function handleMouseMove(e: React.MouseEvent<HTMLCanvasElement>) {
    const mouse = getPoint(e);
    if (!mouse) return;

    const byDistance = (line1: Line, line2: Line) => getDistance(line1, mouse) - getDistance(line2, mouse);

    setLines((lines) => {
      const first = lines.slice().sort(byDistance).at(0);
      if (!first || !inRange(e, first)) return lines.map((line) => ({ ...line, highlighted: false }));
      return lines.map((line) => (line === first ? { ...line, highlighted: true } : { ...line, highlighted: false }));
    });
  }

  return (
    <Container fluid="md">
      <ImageInput onInput={resetImage} />
      {image && (
        <>
          <canvas ref={canvasRef} onClick={handleClick} onContextMenu={handleRightClick} onMouseMove={handleMouseMove}></canvas>
          <Table>
            <thead>
              <tr>
                <th>Länge [px]</th>
                <th>Länge [cm]</th>
                <th>Löschen</th>
              </tr>
            </thead>
            <tbody>
              {lines.map((line) => (
                <LineEntry
                  line={line}
                  highlight={() => highlightLine(line)}
                  blur={blurLines}
                  update={(length) => updateLengths(line, length)}
                  del={() => deleteLine(line)}
                  key={lineToString(line)}
                />
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Container>
  );
};

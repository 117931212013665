export type Point = [number, number];

export interface Line {
  a: Point;
  b: Point;
  length: number;
  highlighted: boolean;
}

export function getLength(line: Line): number {
  const {
    a: [xa, ya],
    b: [xb, yb],
  } = line;
  const [dx, dy] = [xa - xb, ya - yb];
  return Math.sqrt(dx * dx + dy * dy);
}

export function lineToString(line: Line) {
  function coordsToString([x, y]: Point) {
    return `${Math.round(x)};${Math.round(y)}`;
  }

  const result = `${coordsToString(line.a)} - ${coordsToString(line.b)}`;
  return result;
}

export function getDistance({ a, b }: Line, point: Point) {
  function translate([x, y]: Point): [number, number] {
    return [x, -y];
  }

  a = translate(a);
  b = translate(b);
  point = translate(point);

  const [xa, ya] = a;
  const [xb, yb] = b;
  const [xp, yp] = point;

  function d([x, y]: Point) {
    const [dx, dy] = [x - xp, y - yp];
    return Math.sqrt(dx * dx + dy * dy);
  }

  const r = (xa * xa - xa * (xb + xp) + xb * xp + (ya - yp) * (ya - yb)) / ((xa - xb) * (xa - xb) + (ya - yb) * (ya - yb));
  const q = [xa + r * (xb - xa), ya + r * (yb - ya)] as [number, number];

  if (r <= 0) return d(a);
  if (r >= 1) return d(b);
  return d(q);
}

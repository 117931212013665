import moment from "moment";
import { ModuleType } from "../types/attendance";

export function toMoment(date: string) {
  return moment(date, "YYYYMMDDTHHmmss");
}

export function isPart(module: ModuleType, subjectModule: ModuleType) {
  if (subjectModule.group) return module.name == subjectModule.name && (!module.group || module.group == subjectModule.group);
  return module.name == subjectModule.name;
}

export function getModuleString({ name, group }: ModuleType) {
  return group ? `${name} [${group}]` : name;
}

import React, { useRef } from "react";
import { Button, Form } from "react-bootstrap";
import "./ImageInput.sass";

export const ImageInput = ({ onInput }: { onInput: (url: string) => void }) => {
  const ref = useRef<HTMLInputElement>(null);

  function handleUpload() {
    const input = ref.current;
    if (!input) return;

    const files = input.files;
    if (!files) return;
    const images = [...files].map((file) => URL.createObjectURL(file));
    const image = images.pop();
    if (!image) return;
    onInput(image);
  }

  return (
    <div className="imageInput">
      <div className="input mb-3">
        <Button variant="primary" className="button">
          <h4>Bild auswählen</h4>
        </Button>
        <input type="file" onInput={handleUpload} ref={ref} accept="image/*" />
      </div>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";

export const Impressum = () => {
  const contactEmail = "kruggelnoah@gmail.com";

  return (
    <>
      <h1>Impressum</h1>

      <p>
        Noah Kruggel
        <br />
        Ellerried 17
        <br />
        19061 Schwerin
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: +49 1578 2759934
        <br />
        E-Mail: kruggelnoah@gmail.com
      </p>

      <p>
        Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
      </p>
    </>
  );
};

import { Css3Original, Html5Original, JavascriptOriginal, NodejsOriginal, PythonOriginal, TypescriptOriginal } from "devicons-react";
import { getAge } from "../helper/age";

export const age = getAge(new Date("2/22/2005"));

export const skills = [
  { name: "Python", points: 9, Icon: PythonOriginal },
  { name: "HTML", points: 7, Icon: Html5Original },
  { name: "CSS", points: 4, Icon: Css3Original },
  { name: "Javascript", points: 8, Icon: JavascriptOriginal },
  { name: "Node.js", points: 8, Icon: NodejsOriginal },
  { name: "Typescript", points: 7, Icon: TypescriptOriginal },
];

export const languages = [
  { name: "Deutsch", level: "C2 (Muttersprache)" },
  { name: "Englisch", level: "B2" },
  { name: "Französisch", level: "A1" },
];

export const interests = ["Programmieren", "Mathematik", "Physik"];

export const cv = [
  { period: "2011-2015", station: "Grundschule Pädagogium Schwerin" },
  { period: "2015-2017", station: "Orientierungsstufe Pädagogium Schwerin" },
  { period: "2017-2023", station: "Gymnasium Pädagogium Schwerin" },
  { period: "2023-Jetzt", station: "Duales Studium beim DVZ M-V und der BA Leipzig" },
];

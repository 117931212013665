import { io } from "socket.io-client";
import { ModuleType } from "../types/attendance";

const socket = io({
  path: "/api/socket",
  reconnection: true,
  reconnectionDelay: 100,
  autoConnect: false,
});

function initSocket() {
  socket.connect();
}

socket.on("id", (id) => {
  console.log(`Session: ${id}`);
});

export async function test() {
  const response = await (await fetch("/api")).json();
  console.log(response);
}

export async function getTimetable() {
  return (await fetch("/api/timetable")).text();
}

export async function getModules() {
  return await (await fetch("/api/modules")).json();
}

export async function getModuleInfo(): Promise<{ type: string; name: string; start: string; end: string; group?: string }[]> {
  return await (await fetch("/api/moduleinfo")).json();
}

export async function getLocations() {
  return await (await fetch("/api/menu/locations")).json();
}

export async function getMenus() {
  return new Map<string, MenuType[]>(Object.entries(await (await fetch("/api/menu")).json()));
}

export const Socket = {
  socket,
  initSocket,
};

import React from "react";

export const ExternalLink = ({ url, name }: { url: string; name: string }) => {
  return (
    <>
      {" "}
      <a href={url} target="_blank">
        {name}
      </a>{" "}
    </>
  );
};

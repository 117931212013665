import React from "react";
import "./CVEntry.sass";

export const CVEntry = ({ period, station }: { period: string; station: string }) => {
  return (
    <tr className="cv-entry">
      <td>{period}</td>
      <td>{station}</td>
    </tr>
  );
};

import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { getModuleInfo } from "../../services/api";
import "./Timetable.sass";
import { ModuleType } from "../../types/attendance";
import { toMoment } from "../../helper/attendance";
import { TimetableEntry } from "./TimetableEntry";
import { Link } from "react-router-dom";
import { toString } from "../../helper/timetable";

const emptyEntry = (id: number) => ({ id, name: undefined, group: undefined } as Entry);
const getUrl = (entries: Entry[]) => {
  return `${window.location.origin}/api/timetable/?${new URLSearchParams(
    entries.filter(({ name, group }) => !!name && !!group).map((module) => [toString(module as any as ModuleType), module.group!])
  )}`;
};

export const Timetable = () => {
  const [modules, setModules] = useState<ModuleType[]>([]);
  const [entries, setEntries] = useState<Entry[]>([emptyEntry(0)]);
  const [url, setUrl] = useState<string>(getUrl([]));

  useEffect(() => {
    getModuleInfo().then((modules) =>
      setModules(modules.map(({ type, name, start, end, group }) => ({ type, name, start: toMoment(start), end: toMoment(end), group } as ModuleType)))
    );
  }, []);

  useEffect(() => {
    console.log(entries);
    setUrl(getUrl(entries));
  }, [entries]);

  function hasGroups(module: ModuleType) {
    return modules.some((m) => m.type === module.type && m.name === module.name && m.group);
  }

  function updateEntry(entry: Entry) {
    setEntries((entries) => entries.map((e) => (e.id === entry.id ? entry : e)));
  }

  function deleteEntry(entry: Entry) {
    console.log(entry);
    setEntries((entries) => entries.filter((e) => e.id !== entry.id));
  }

  function createEntry() {
    setEntries((entries) => [...entries, emptyEntry(entries.map(({ id }) => id).reduce((a, b) => (a > b ? a : b), -1) + 1)]);
  }

  return (
    <>
      <Form.Label htmlFor="basic-url">Hier ist die Url:&nbsp;</Form.Label>
      {/* <Form.Control id="basic-url" aria-describedby="basic-addon3" data-bs-theme="dark" readOnly value={url} /> */}
      <br />
      <Link to={url} target="_blank" style={{ overflowWrap: "anywhere" }}>
        {url}
      </Link>
      <br />
      <br />
      <Form>
        {entries.map((entry) => (
          <TimetableEntry
            key={entry.id}
            modules={modules.filter(hasGroups).filter((m) => !entries.some((e) => e.id !== entry.id && e.name === m.name))}
            entry={entry}
            onSelect={updateEntry}
            onDelete={deleteEntry}
          />
        ))}
      </Form>
      <Row>
        <Col>
          <Button onClick={createEntry}>Modul hinzufügen</Button>
        </Col>
      </Row>
    </>
  );
};

import React, { useEffect, useRef } from "react";
import { getLength, Line, lineToString } from "../../helper/line";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { round } from "../../helper/math";

export const LineEntry = ({
  line,
  highlight,
  blur,
  update,
  del,
}: {
  line: Line;
  highlight: () => void;
  blur: () => void;
  update: (length: number) => void;
  del: () => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const input = inputRef.current;
    if (!input || !line.length) return;
    input.value = line.length + "";
  }, [line]);

  function handleInput(e: React.FormEvent<HTMLInputElement>) {
    const length = (e.target as HTMLInputElement).value || 0;
    line.length = +length;
    update(+length);
  }

  const rule = line.highlighted && { style: { backgroundColor: "yellow", color: "black" } };

  return (
    <tr>
      <td {...rule}>{round(getLength(line))}</td>
      <td {...rule}>
        <Form.Control type="number" onFocus={highlight} onBlur={blur} onInput={handleInput} ref={inputRef} />
      </td>
      <td {...rule}>
        <Button variant="danger" onClick={del}>
          <Trash />
        </Button>
      </td>
    </tr>
  );
};

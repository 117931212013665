export function round(x: number, d: number = 0) {
  const f = Math.pow(10, d);
  return Math.round(x * f) / f;
}

export function floor(x: number, d: number = 0) {
  const f = Math.pow(10, d);
  return Math.floor(x * f) / f;
}

export function ceil(x: number, d: number = 0) {
  const f = Math.pow(10, d);
  return Math.ceil(x * f) / f;
}

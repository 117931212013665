import React from "react";
import "./NotFound.sass";
import { Alert, Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <Container fluid="md" className="d-flex h-100 justify-content-center align-items-center">
      <Alert variant="danger">
        <Alert.Heading>Fehler 404 - Nicht gefunden</Alert.Heading>
        Die angegeben Seite kann nicht gefunden werden.
        <br />
        Geben Sie eine gültige Seite an oder kehren Sie zur Homepage zurück.
        <hr />
        <div>
          <Link to="/" className="d-flex justify-content-end" type="button">
            <Button>Home</Button>
          </Link>
        </div>
      </Alert>
    </Container>
  );
};

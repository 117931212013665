import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/Home/Home";
import { NotFound } from "./pages/NotFound/NotFound";
import { Layout } from "./layout/Layout";
import { About } from "./pages/About/About";
import { Impressum } from "./pages/Impressum/Impressum";
import { SocketContext } from "./contexts/SocketContext";
import { Socket } from "./services/api";
import { Timetable } from "./pages/Timetable/Timetable";
import { Menu } from "./pages/Menu/Menu";
import { DigitalRuler } from "./pages/DigitalRuler/DigitalRuler";
import { Privacy } from "./pages/Privacy/Privacy";
import { Attendance } from "./pages/Attendance/Attendance";

function App() {
  Socket.initSocket();

  return (
    <SocketContext.Provider value={Socket.socket}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/menu" element={<Menu detailed={false} />} />
            <Route path="/menu/detailed" element={<Menu detailed />} />
            <Route path="/about" element={<About />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/timetable" element={<Timetable />} />
            <Route path="/ruler" element={<DigitalRuler />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </SocketContext.Provider>
  );
}

export default App;

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const SocialLink = ({ icon, url }: { icon: IconProp; url: string }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={icon} size="2x"></FontAwesomeIcon>
    </a>
  );
};

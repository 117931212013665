import React from "react";
import "./LanguageLevels.sass";

export const LanguageLevels = ({ name, level }: { name: string; level: string }) => {
  return (
    <tr className="language-level">
      <td>{name}</td>
      <td>{level}</td>
    </tr>
  );
};

import { Outlet } from "react-router-dom";
import "./Layout.sass";
import { CustomNavbar } from "./CustomNavbar/CustomNavbar";
import { CustomFooter } from "./CustomFooter/CustomFooter";


export const Layout = () => {
  return (
    <div className="main-container">
      <CustomNavbar />
      <div className="outlet">
        <Outlet />
      </div>
      <CustomFooter />
    </div>
  );
};

import moment from "moment";
import React, { useEffect } from "react";
import { Form, FormControl, FormGroup, FormSelect, InputGroup, Spinner, Stack } from "react-bootstrap";
import { getModuleInfo } from "../../services/api";
import { floor } from "../../helper/math";
import { ModuleType } from "../../types/attendance";
import { getModuleString, isPart, toMoment } from "../../helper/attendance";

export const Attendance = () => {
  const [loading, setLoading] = React.useState(true);
  const [modules, setModules] = React.useState<ModuleType[]>([]);
  const [module, setModule] = React.useState<ModuleType>();
  const [attendant, setAttendant] = React.useState<number>(0);

  useEffect(() => {
    if (!modules) return;
    console.log(modules);
    setLoading(false);
  }, [modules]);

  useEffect(() => {
    getModuleInfo().then((modules) =>
      setModules(modules.map(({ name, start, end, group }) => ({ name, start: toMoment(start), end: toMoment(end), group } as ModuleType)))
    );
  }, []);

  function getModules(module: ModuleType) {
    return modules.filter((m) => isPart(m, module));
  }

  function getModuleByString(module: string) {
    return modules.find((m) => getModuleString(m) == module);
  }

  function hasGroups(module: ModuleType) {
    return modules.some((m) => m.name == module.name && m.group);
  }

  return (
    <>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <Stack gap={3}>
          <FormSelect onChange={(e) => setModule(getModuleByString(e.target.value))}>
            <option value="">--Modul auswählen--</option>
            {[...new Set(modules.filter((m) => (hasGroups(m) ? m.group : true)).map(getModuleString))].map((module) => (
              <option key={module} value={module}>
                {module}
              </option>
            ))}
          </FormSelect>
          {!!module && (
            <InputGroup>
              <InputGroup.Text>Gewesen: {getModules(module).filter((mod) => moment().isAfter(mod.end)).length}</InputGroup.Text>
              <FormControl onChange={(e) => setAttendant(+e.target.value)}></FormControl>
              <InputGroup.Text> / {getModules(module).length} Modulen</InputGroup.Text>
              <InputGroup.Text> ≙ {floor((100 * attendant) / getModules(module).length, 1)}% Anwesenheit</InputGroup.Text>
            </InputGroup>
          )}
        </Stack>
      )}
    </>
  );
};
